<template id="channel-statistic">
  <div class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">{{ $t('Channel Statistic') }}</h3>
    </div>
    <div class="card-body chart-height">
      <apexchart id="channelStatistic"
                 :height="height"
                 type="bar"
                 :options="channelStatistic.options"
                 :series="channelStatistic.series"></apexchart>
    </div>
  </div>
</template>
<script>
import i18n from "../../../../tools/src/core/plugins/vue-i18n";
import {_elementUtil} from "../../../../tools/src/core/util/element-util";

export default {
  name: "ChannelStatistic",
  components: {},
  props: {
    category: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
    }
  },
  data() {
    return {
      channelStatistic: {
        options: {
          chart: {
            id: 'channelStatistic',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            }
          },
          tooltip: {
            shared: true,
            intersect: false
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
          },
        },
        series: [
          {
            name: "Review Score",
            data: []
          },
          {
            name: "Hotel Score",
            data: []
          },
          {
            name: "Rooms Score",
            data: []
          },
          {
            name: "Meal Score",
            data: []
          },
          {
            name: "Service Score",
            data: []
          },
          {
            name: "Staff Score",
            data: []
          },
          {
            name: "Location Score",
            data: []
          },
          {
            name: "Transportation Score",
            data: []
          },
          {
            name: "Cleanness Score",
            data: []
          },
          {
            name: "Activities Score",
            data: []
          }],
        noData: {
          text: i18n.t('Loading', 'Loading...')
        }
      }
    }
  },
  mounted() {
    this.getChannelStatistics();
  },
  methods: {
    setChannelStatistics(response) {
      let series = [];
      if (this.category !== null) {
        series = [
          {
            name: this.category,
            data: []
          }
        ];
        Object.entries(response.data.reviewStat).map((data) => {
          this.channelStatistic.options.xaxis.categories.push(data[0]);
          series[0].data.push(data[1][this.category] !== null ? data[1][this.category].toFixed(2) : 0);
        });
      } else {
        series = [
          {
            name: "Review Score",
            data: []
          },
          {
            name: "Hotel Score",
            data: []
          },
          {
            name: "Rooms Score",
            data: []
          },
          {
            name: "Meal Score",
            data: []
          },
          {
            name: "Service Score",
            data: []
          },
          {
            name: "Staff Score",
            data: []
          },
          {
            name: "Location Score",
            data: []
          },
          {
            name: "Transportation Score",
            data: []
          },
          {
            name: "Cleanness Score",
            data: []
          },
          {
            name: "Activities Score",
            data: []
          }
        ];
        Object.entries(response.data.reviewStat).map((data) => {
          this.channelStatistic.options.xaxis.categories.push(data[0]);
          series[0].data.push(data[1].reviewScore !== null ? data[1].reviewScore.toFixed(2) : 0);
          series[1].data.push(data[1].roomsScore !== null ? data[1].hotelScore.toFixed(2) : 0);
          series[2].data.push(data[1].roomsScore !== null ? data[1].roomsScore.toFixed(2) : 0);
          series[3].data.push(data[1].mealScore !== null ? data[1].mealScore.toFixed(2) : 0);
          series[4].data.push(data[1].staffScore !== null ? data[1].staffScore.toFixed(2) : 0);
          series[5].data.push(data[1].serviceScore !== null ? data[1].serviceScore.toFixed(2) : 0);
          series[6].data.push(data[1].locationScore !== null ? data[1].locationScore.toFixed(2) : 0);
          series[7].data.push(data[1].transportationScore !== null ? data[1].transportationScore.toFixed(2) : 0);
          series[8].data.push(data[1].transportationScore !== null ? data[1].cleannessScore.toFixed(2) : 0);
          series[9].data.push(data[1].activitiesScore !== null ? data[1].activitiesScore.toFixed(2) : 0);
        });
      }


      this.channelStatistic.series = series;
    },
    getChannelStatistics() {
      let _this = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/review/stats/channel', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          "params": {
            "fromDate": "2021-08-01",
            "toDate": "2021-12-31",
            "hotelId": null,
            "category": _this.category
          }
        }),
        callBack: function (elt, response) {
          _this.setChannelStatistics(response);
        }
      });
    }
  }
};
</script>