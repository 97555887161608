<template id="polar-area-chart">
  <div class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">{{ label}}</h3>
    </div>
    <div class="card-body chart-height">
      <apexchart id="polarAreaChart"
                 height="450"
                 type="polarArea"
                 :options="polarAreaChart.options"
                 :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import i18n from "../../../../tools/src/core/plugins/vue-i18n";

export default {
  name: "PolarAreaChart",
  components: {},
  data() {
    return {
      polarAreaChart: {
        options: {
          chart: {
            width:'100%',
            type: 'polarArea',
          },
          fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: undefined
          },
          yaxis: {
            show: false
          },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0
              },
              spokes: {
                strokeWidth: 0
              },
            }
          },
          labels: this.optionLabels,
          colors: this.optionColors,
        },
        noData: {
          text: i18n.t('Loading', 'Loading...')
        }
      }
    }
  },
  props: {'label':String,'series':{
      type: Array,
      default: function () { return [] }
    },'optionLabels':{
      type: Array,
      default: function () { return ['Terrible','Low','Moderate','High','Extreme'] }
    },'optionColors':{
      type: Array,
      default: function () { return ['#D30202', '#FFB200', '#128FD9', '#49d754', '#00A100'] }
    }},
  mounted() {

  },

  methods: {
  }
};
</script>
