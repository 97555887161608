<template id="progress-stat">
  <div :class="'progress-stat card card-custom bg-'+ progressStatData.bgColor +' card-stretch gutter-b'">
    <div class="card-body custom-flexbox vertical-flexbox">
      <span class="text-white fs-14px nowrap">{{ progressStatData.title }}</span>
      <a href="javascript:void(0)"
         :class="'card-title text-'+progressStatData.textColor+' font-weight-bolder font-size-h6 position-relative custom-flexbox align-items-center'">
        <i class="flaticon-chat text-white fs-34px"></i>
        <div class="text">{{ progressStatData.category }}</div>
      </a>
      <div class="custom-flexbox align-items-center justify-content-space-between">
        <div :class="'position-relative font-weight-bold font-size-sm custom-flexbox align-items-center text-'+progressStatData.textColor">
          <i :class="'flaticon2-'+ (progressStatData.isUp ? 'up' : 'down' ) +' text-'+progressStatData.textColor+' average-icon'"></i>
          <div class="fs-34px font-weight-bolder">{{ progressStatData.percent }}%</div>
        </div>
        <div v-if="progressStatData.total !== null"
             :class="'position-relative font-weight-bold text-align-right font-size-sm text-'+progressStatData.textColor">
          <span id="progress-stat-total" class="total">
            <ICountUp
                :startVal="startVal"
                :endVal="progressStatData.total"
                :decimals="decimals"
                :duration="duration"
                :options="options"
                @ready="onReady"
            />
            {{ $t('Reviews') }}
          </span>
        </div>
      </div>
      <div :class="'progress progress-xs bg-'+progressStatData.textColor+'-o-90'">
        <div :class="'progress-bar bg-'+progressStatData.textColor"
             role="progressbar"
             :style="'width: '+ progressStatData.percent +'%;'"
             aria-valuenow="50"
             aria-valuemin="0"
             aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</template>
<style>
.progress-stat .card-title span.text {
  position: absolute;
  top: 10px;
  left: 50px;
}

.progress-stat .total {
  position: relative;
  bottom: -5px;
  font-size: 18px;
}

.progress-stat .average-icon {
  font-size: 20px;
}

.progress-stat.bg-success {
  background-color: #00e396 !important;
}

.iCountUp {
  font-size: 12em;
  margin: 0;
  color: #4d63bc;
}
.card-title {
  margin: 0px;
}
</style>
<script>
import ICountUp from 'vue-countup-v2';

export default {
  name: "ProgressStat",
  components: {ICountUp},
  props: {
    progressStatData: {
      bgColor: String,
      textColor: String,
      title: String,
      category: String,
      percent: Number,
      isUp: Boolean,
      total: Number,
    }
  },
  data() {
    return {
      startVal: 0,
      decimals: 0,
      duration: 2.5,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    };
  },
  mounted() {

  },
  updated() {

  },
  methods: {
    onReady: function(instance, CountUp) {
      const that = this;
      instance.update(that.progressStatData.total);
    }
  }
}
</script>