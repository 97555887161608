<template id="heat-map-chart">
  <div class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">{{ heatMapLabel }}</h3>
    </div>
    <div class="card-body chart-height">
      <apexchart id="heatMapChart"
                 :height="heatMapHeight"
                 type="heatmap"
                 :options="heatMapChart.chartOptions"
                 :series="heatMapSeries"></apexchart>
    </div>
  </div>
</template>

<script>

export default {
  name: "HeatMapChart",
  components: {},
  data() {
    return {
      heatMapChart: {
        chartOptions: {
          chart: {
            type: 'heatmap',
            background: '#fff'
          },
          plotOptions: {
            heatmap: {
              radius: 2,
              enableShades: true,
              shadeIntensity: 0.5,
              reverseNegativeShade: true,
              distributed: false,
              useFillColorAsStroke: false,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 1.99,
                    name: 'Terrible',
                    color: '#d30202'
                  },
                  {
                    from: 2,
                    to: 3.99,
                    name: 'Low',
                    color: '#FFB200'
                  },
                  {
                    from: 4,
                    to: 5.99,
                    name: 'Medium',
                    color: '#128FD9'
                  },
                  {
                    from: 6,
                    to: 7.99,
                    name: 'High',
                    color: '#49d754'
                  },
                  {
                    from: 8,
                    to: 10,
                    name: 'Extreme',
                    color: '#00A100'
                  }
                ]
              }
            }
          },
          dataLabels: {
            enabled: false
          }
        }
      }
    }
  },
  props:["heatMapSeries","heatMapLabel","heatMapHeight"],
  mounted() {
  },
  methods: {
    generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = 'w' + (i + 1).toString();
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }
  }
};
</script>