<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-4">
        <progress-stat :progressStatData="progressStat.rising"></progress-stat>
      </div>
      <div class="col-12 col-md-4">
        <progress-stat :progressStatData="progressStat.falling"></progress-stat>
      </div>
      <div class="col-12 col-md-4">
        <progress-stat :progressStatData="progressStat.reviewCount"></progress-stat>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <polar-area-chart :label="meanStatName"
                          :series="reviewDistribution"
                          :optionLabels="reviewDistributionLabels"
                          :optionColors="reviewDistributionColors"></polar-area-chart>
      </div>

      <div class="col-12 col-md-6">
        <channel-statistic :height="450"></channel-statistic>
      </div>

      <div class="col-12 col-md-6">
        <donut-chart :label="perChannelReview"
                     :series="reviewPerChannel"
                     :dataLabels="channelList"
                     :height="600"></donut-chart>
      </div>

      <div class="col-12 col-md-6">
        <heat-map-chart :heatMapSeries="monthlyTalked"
                        :heatMapLabel="monthlyTalkedName"
                        :heatMapHeight="390"></heat-map-chart>
      </div>
    </div>
  </div>
</template>

<style>
.chart-height {
  height: 520px;
}
</style>

<script>

import {coreJs} from "utilPath/coreJs";
import {_elementUtil} from "utilPath/element-util";
import ChannelStatistic from "../content/charts/ChannelStatistic";
import HeatMapChart from "../content/charts/HeatMapChart";
import DonutChart from "../content/charts/DonutChart";
import ProgressStat from "../content/stats/ProgressStat";
import PolarAreaChart from "../content/charts/PolarAreaChart";

export default {
  name: "dashboard",
  components: {ProgressStat, ChannelStatistic, HeatMapChart, DonutChart, PolarAreaChart},
  data() {
    return {
      name: this.$t('Dashboard', {value: "Dashboard"}),
      title: this.$t('Dashboard', {value: "Dashboard"}),
      monthlyTalkedName: this.$t('Monthly Talked', {value: "Monthly Talked"}),
      monthlyTalked: [],
      meanStatName: this.$t('Review Distribution', {value: "Review Distribution"}),
      reviewDistribution: [],
      reviewDistributionLabels: ['Terrible', 'Low', 'Modarate', 'High', 'Extreme'],
      reviewDistributionColors: ['#D30202', '#FFB200', '#128FD9', '#49d754', '#00A100'],
      perChannelReview: this.$t('Reviews Per Channel', {value: "Reviews Per Channel"}),
      channelList: [],
      reviewPerChannel: [],
      progressStat: {
        rising: {
          isUp: false,
          bgColor: null,
          textColor: null,
          title: null,
          category: null,
          percent: 0,
          total: null,
        },
        falling: {
          isUp: false,
          bgColor: null,
          textColor: null,
          title: null,
          category: null,
          percent: 0,
          total: null,
        },
        reviewCount: {
          isUp: false,
          bgColor: null,
          textColor: null,
          title: null,
          category: null,
          percent: 0,
          total: null,
        }
      }
    }
  },
  mounted() {
    coreJs.init({name: this.name, title: this.title});
    this.getMonthlyStatics();
    this.getReviewDistributions();
    this.getReviewPerChannel();
    this.getProgressStat();
  },
  methods: {
    setMonthlyStatics(response) {
      let monthlySeries = [];
      let cnt = 0;
      Object.entries(response.data.monthlyStat).map((data) => {
        let dataX = {
          name: data[0],
          data: []
        };
        dataX.data.push({x: "Review Score", y: data[1].reviewScore !== null ? data[1].reviewScore.toFixed(2) : 0});
        dataX.data.push({x: "Hotel Score", y: data[1].hotelScore !== null ? data[1].hotelScore.toFixed(2) : 0});
        dataX.data.push({x: "Room Score", y: data[1].roomsScore !== null ? data[1].roomsScore.toFixed(2) : 0});
        dataX.data.push({x: "Meal Score", y: data[1].mealScore !== null ? data[1].mealScore.toFixed(2) : 0});
        dataX.data.push({x: "Staff Score", y: data[1].staffScore !== null ? data[1].staffScore.toFixed(2) : 0});
        dataX.data.push({x: "Service Score", y: data[1].serviceScore !== null ? data[1].serviceScore.toFixed(2) : 0});
        dataX.data.push({
          x: "Location Score",
          y: data[1].locationScore !== null ? data[1].locationScore.toFixed(2) : 0
        });
        dataX.data.push({
          x: "Transportation Score",
          y: data[1].transportationScore !== null ? data[1].transportationScore.toFixed(2) : 0
        });
        dataX.data.push({
          x: "Cleanness Score",
          y: data[1].cleannessScore !== null ? data[1].cleannessScore.toFixed(2) : 0
        });
        dataX.data.push({
          x: "Activities Score",
          y: data[1].activitiesScore !== null ? data[1].activitiesScore.toFixed(2) : 0
        });
        monthlySeries.push(dataX);

      });
      this.monthlyTalked = monthlySeries;

    },
    getMonthlyStatics() {
      let _this = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/review/stats/monthly', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          params: {
            channel: null,
            hotelId: null
          }
        }),
        callBack: function (elt, response) {
          _this.setMonthlyStatics(response);
        }
      });
    },
    setReviewDistributions(response) {
      let reviewDistributions = [];
      Object.entries(response.data.reviewStat).map((data) => {


        reviewDistributions.push(data[1].reviews);
      });

      this.reviewDistribution = reviewDistributions;

    },
    getReviewDistributions() {
      let _this = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/review/stats/review-distribution', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          params: {
            channel: null,
            hotelId: null
          }
        }),
        callBack: function (elt, response) {
          _this.setReviewDistributions(response);
        }
      });
    },
    setReviewPerChannel(response) {
      let series = [];
      Object.entries(response.data.reviewStat).map((data) => {
        this.channelList.push(data[0]);
        series.push(data[1].reviews !== null ? data[1].reviews : 0);

      });

      this.reviewPerChannel = series;
    },
    getReviewPerChannel() {
      let _this = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/review/stats/channel', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          "params": {
            "fromDate": "2021-08-01",
            "toDate": "2021-12-31",
            "hotelId": null
          }
        }),
        callBack: function (elt, response) {
          _this.setReviewPerChannel(response);
        }
      });
    },
    getProgressStat() {
      let _this = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/review/stats/progress-stat', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          params: {
            hotelId: null
          }
        }),
        callBack: function (elt, response) {
          _this.setProgressStat(response);
        }
      });
    },
    setProgressStat(response) {
      let progressData = response.data.progressData;
      this.progressStat.rising.isUp = true;
      this.progressStat.rising.bgColor = 'success';
      this.progressStat.rising.textColor = 'white';
      this.progressStat.rising.title = this.$t('Most rising category by previous month');
      this.progressStat.rising.category = progressData.rising.name;
      this.progressStat.rising.percent = progressData.rising.diff;

      this.progressStat.falling.isUp = false;
      this.progressStat.falling.bgColor = 'danger';
      this.progressStat.falling.textColor = 'white';
      this.progressStat.falling.title = this.$t('Most falling category by previous month');
      this.progressStat.falling.category = progressData.falling.name;
      this.progressStat.falling.percent = progressData.falling.diff;

      this.progressStat.reviewCount.isUp = false;
      this.progressStat.reviewCount.bgColor = 'danger';
      this.progressStat.reviewCount.textColor = 'white';
      this.progressStat.reviewCount.title = this.$t('Review count by previous month');
      this.progressStat.reviewCount.category = 'Review Count';
      this.progressStat.reviewCount.percent = progressData.reviewCount.diff;
      this.progressStat.reviewCount.total = progressData.reviewCount.total;

    }
  }
};
</script>
