<template id="donut-chart">
  <div class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">{{ label }}</h3>
    </div>
    <div class="card-body chart-height">
      <apexchart id="donutChart"
                 width="100%"
                 height="100%"
                 type="donut"
                 :options="donutChart.options"
                 :series="series"></apexchart>
    </div>
  </div>
</template>

<script>
import i18n from "../../../../tools/src/core/plugins/vue-i18n";

export default {
  name: "DonutChart",
  components: {},
  props: {
    label: {
      type: String
    },
    series: {
      type: Array
    },
    dataLabels: {
      type: Array
    },
    height: {
      type: Number
    }
  },
  data() {
    return {
      donutChart: {
        options: {
          chart: {
            width: '100%',
            type: 'donut',
          },
          labels: this.dataLabels
        },
        plotOptions: {
          donut: {
            size: '100%'
          }
        },
        noData: {
          text: i18n.t('Loading', 'Loading...')
        },
        legend: {
          position: "right",
          verticalAlign: "top",
          containerMargin: {
            left: 35,
            right: 60
          }
        },
        responsive: [
          {
            breakpoint: 300,
            options: {
              plotOptions: {
                donut: {
                  size: '100%'
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    }
  },

  mounted() {
  },
  updated() {
  },
  methods: {}
};
</script>
